import { useMutation } from '@apollo/client';
import { OptionInterface, Spinner } from '@kalecard/common';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UPDATE_CHALLENGE } from '../../graphql/mutations';
import { SIMPLE_BRANDS } from '../../graphql/queries';
import { getAllBrandCategories, getAllBrands } from '../../utils/brands';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../catalyst/button';
import {
  ChallengeFormDataComponent,
  getRoundedDate,
} from '../challenges/SharedChallengeComponents';
import {
  Brand,
  Challenge,
  PostRequirement,
  ChallengeTemplate
} from '../../__generated__/graphql';

type EditChallengeFormInput = {
  description: string;
  audienceType: string;
  newCreatorId: string;
  exampleUrl: string;
  newTag: string;
  title?: string;
};

interface EditChallengeFormInterface {
  challenge: Challenge;
  onSubmit?: (challenge: Challenge) => void;
}

export default function EditChallengeForm({
  challenge,
  onSubmit,
}: EditChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { register, handleSubmit, watch, getValues, setValue } =
    useForm<EditChallengeFormInput>({
      defaultValues: {
        description: challenge.description,
        audienceType: challenge.audienceType,
        exampleUrl: challenge.exampleUrl,
        title: challenge.title,
      },
    });
  const [difficulty, setDifficulty] = useState(
    !challenge.difficulty || challenge.difficulty === 0
      ? 1
      : challenge.difficulty
  );
  const [brands, setBrands] = useState<Brand[]>([]);

  const getInitialChallengePostRequirements = () => {
    return challenge.postRequirements;
  };

  const [brandCategories, setBrandCategories] = useState<OptionInterface[]>([]);
  // const [showAudience, setShowAudience] = useState<boolean>(false);
  const [brandAudience, setBrandAudience] = useState<OptionInterface[]>([]);
  const [selectedBrandProduct, setSelectedBrandProduct] = useState(
    challenge.requiredBrandProducts?.[0]
  );
  const [brandCategoriesAudience, setBrandCategoriesAudience] = useState<
    OptionInterface[]
  >([]);
  const [creatorsAudience, setCreatorsAudience] = useState<Set<string>>(
    new Set()
  );
  const [startDate, setStartDate] = useState<Date>(
    challenge.startDate ? new Date(Number(challenge.startDate)) : null
  );
  const [endDate, setEndDate] = useState<Date>(
    challenge.endDate ? new Date(Number(challenge.endDate)) : null
  );
  const [challengeTemplate, setChallengeTemplate] = useState(
    challenge.challengeTemplate
  );

  const [challengePostRequirements, setChallengePostRequirements] = useState(
    getInitialChallengePostRequirements()
  );

  const [updateChallenge] = useMutation(UPDATE_CHALLENGE);

  useEffect(() => {
    getAllBrandCategories().then((brandCategories) => {
      setBrandCategories(
        brandCategories.map((category) => {
          return {
            id: category.id,
            name: category.name,
            imageUrl: null,
          };
        })
      );
    });
    getAllBrands(SIMPLE_BRANDS).then((brands) => {
      setBrands(brands as Brand[]);
    });
  }, []);

  useEffect(() => {
    setBrandAudience(initAudienceValues(challenge));
    setBrandCategoriesAudience(
      challenge.audience.brandCategories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          imageUrl: null,
        };
      })
    );
    setCreatorsAudience(
      new Set(challenge.audience.creators.map((creator) => creator.id))
    );
  }, [challenge]);

  const updateState = async (state: string, action: string) => {
    try {
      const result = await updateChallenge({
        variables: {
          challengeId: challenge.id,
          state: state,
        },
      });
      console.log(result);
      if (result.data.updateChallenge) {
        onSubmit(result.data.updateChallenge as Challenge);
      } else {
        setError('Failed to' + action + 'the challenge. Try again.');
      }
    } catch (err) {
      console.log(err);
      setError('Failed to' + action + 'the challenge. Try again.');
    }
  };

  const submit: SubmitHandler<EditChallengeFormInput> = async (
    data: EditChallengeFormInput
  ) => {
    console.log(data);
    setIsLoading(true);

    const isBrandSuggestedChallenge =
      challenge.state === 'PENDING_REVIEW' &&
      challenge.source === 'BRAND_ADMIN';

    if (
      isBrandSuggestedChallenge &&
      (!challengeTemplate || !data.title) &&
      !challenge.challengeTemplate
    ) {
      setError('Template and title are required.');
      setIsLoading(false);
      return;
    }

    try {
      const exampleUrl = getValues('exampleUrl');
      const result = await updateChallenge({
        variables: {
          description: data.description,
          challengeId: challenge.id,
          audienceType: data.audienceType,
          brandIds: brandAudience.map((option) => option.id),
          brandCategoryIds: brandCategoriesAudience.map((option) => option.id),
          creatorIds: Array.from(creatorsAudience),
          exampleUrl: exampleUrl,
          difficulty: difficulty,
          startDate: getRoundedDate(startDate)?.toString(), // round down to nearest second
          endDate: getRoundedDate(endDate)?.toString(), // round down to nearest second
          state: isBrandSuggestedChallenge ? 'INACTIVE' : null,
          brandProductId: selectedBrandProduct?.id,
          challengePostRequirements: challengePostRequirements?.map(
            (postRequirement) => postRequirement.id
          ),
          templateId: challengeTemplate?.id,
          title: data.title,
        },
      });
      console.log(result);
      if (result.data.updateChallenge) {
        onSubmit(result.data.updateChallenge as Challenge);
      } else {
        setError('Failed to update the challenge. Try again.');
      }
    } catch (err) {
      console.log(err);
      setError('Failed to update the challenge. Try again.');
    }
    setIsLoading(false);
  };

  return (
    <form
      className="w-full space-y-4"
      onKeyDown={(e) => {
        // Prevent this form from submitting when the user presses enter inside
        // the form on a field; specifically annoying in hashtag selector
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      {/* Brand */}
      <div className="flex items-center justify-between pt-2">
        <div className="flex items-center space-x-2">
          <img
            className="h-8 w-8 rounded-full"
            src={challenge.brand?.logoUrl}
            alt=""
          />
          <p className="text-md font-semibold">
            {challenge.brand?.name} Challenge {`(ID: ${challenge.id})`}
          </p>
        </div>
        <div>
          {challenge.state === 'PENDING_REVIEW' &&
            challenge.source === 'BRAND_ADMIN' &&
            challenge.brand?.isChallengeReviewRequired && (
              <p className="text-sm font-medium">
                🔔 Challenge Review Required
              </p>
            )}
        </div>
      </div>

      <ChallengeFormDataComponent
        brands={brands as Brand[]}
        selectedBrandId={challenge.brand?.id}
        challengeTemplate={challengeTemplate as ChallengeTemplate}
        setChallengeTemplate={setChallengeTemplate}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBrandProduct={selectedBrandProduct}
        setSelectedBrandProduct={setSelectedBrandProduct}
        challengePostRequirements={
          challengePostRequirements as PostRequirement[]
        }
        setChallengePostRequirements={setChallengePostRequirements}
        setError={setError}
        isEditForm={true}
        currentChallenge={challenge}
        type={challenge?.type}
        setType={() => {
          // Cannot edit challenge type after creation
          throw new Error('Cannot edit challenge type after creation');
        }}
      />

      <div className="flex justify-between space-x-2 pt-5">
        <Button
          type="button"
          className="z-0 hover:cursor-pointer"
          onClick={() => {
            if (
              challenge.state === 'PENDING_REVIEW' &&
              challenge.source === 'BRAND_ADMIN'
            ) {
              updateState('REJECTED', 'reject');
            } else {
              updateState('DELETED', 'delete');
            }
          }}
          disabled={isLoading}
          color="red"
        >
          {challenge.state === 'PENDING_REVIEW' &&
          challenge.source === 'BRAND_ADMIN'
            ? 'Reject'
            : 'Delete'}
        </Button>

        {isLoading && (
          <div className="flex flex-wrap content-center justify-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}

        <Button
          type="button"
          onClick={handleSubmit(submit)}
          disabled={isLoading}
          color="emerald"
          className="hover:cursor-pointer"
        >
          {challenge.state === 'PENDING_REVIEW' &&
          challenge.source === 'BRAND_ADMIN'
            ? 'Approve'
            : 'Update'}
        </Button>
      </div>

      <div className="mt-2 flex justify-end">
        <p className="font-bold text-red-500">{error}</p>
      </div>
    </form>
  );
}
function initAudienceValues(challenge: Challenge): OptionInterface[] {
  if (challenge.audienceType === 'TARGETED') {
    return challenge.audience.brands.map((brand) => {
      return {
        id: brand.id,
        name: brand.name,
        imageUrl: brand.logoUrl,
      };
    });
  } else {
    return [
      {
        id: challenge.brand.id,
        name: challenge.brand.name,
        imageUrl: challenge.brand.logoUrl,
      },
    ];
  }
}
