import apolloClient from '../config/apolloClient';
import { GET_UPLOAD_SIGNED_URL } from '../graphql/mutations';

export interface UploadRequestMetadata {
  data: { key: string; value: string }[];
}

export interface UploadMetadata {
  mimeType: string;
  uploadType: string;
  bucketPath: string;
  signedUrl: string;
}

const isDevEnvirontment = () => {
  return process.env.NODE_ENV === 'development';
};

async function getUploadMetadata(
  file: File,
  uploadType: string,
  requestMetadata: UploadRequestMetadata
) {
  const result = await apolloClient.mutate({
    mutation: GET_UPLOAD_SIGNED_URL,
    variables: {
      mimeType: file.type,
      uploadType,
      metadata: requestMetadata,
    },
  });
  return result.data.getUploadSignedUrl;
}

export async function upload(
  file: File,
  uploadType: string,
  requestMetadata: UploadRequestMetadata,
  completeCallback?: (uploadMetadata: UploadMetadata) => void,
  setError?: (error: string) => void
) {
  // Get presigned url from the server
  const metadata = await getUploadMetadata(file, uploadType, requestMetadata);
  console.log(metadata);

  // Upload file to the presigned url
  if (isDevEnvirontment()) {
    if (completeCallback) {
      completeCallback(metadata);
    }
    return;
  }
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', metadata.signedUrl, true);
  xhr.onload = () => {
    const status = xhr.status;
    if (status === 200) {
      // setState(UploadFormState.CHOOSE);
      console.log('File is uploaded', xhr.response);
      if (completeCallback) {
        completeCallback(metadata);
      }
      // reset();
    } else {
      // setState(UploadFormState.UPLOAD);
      console.log('Something went wrong!', xhr.response);
      if (setError) {
        setError(
          'Something went wrong when trying to upload the file! Please refresh this page and try again.'
        );
      }
    }
  };

  xhr.onerror = () => {
    console.log('Something went wrong!', xhr.response);
    if (setError) {
      setError(
        'Something went wrong when trying to upload the file! Please refresh this page and try again.'
      );
    }
  };
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
  return metadata;
}
