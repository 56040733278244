import { useMutation } from "@apollo/client";
import { timeDifferenceForDate } from "@kalecard/common";
import { Deal } from '../../../__generated__/graphql'
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { APPROVE_RECEIPT_DEAL } from "../../../graphql/mutations";
import DealStateComponent from "../../DealStateComponent";
import PayDealForm from "../../forms/PayDealForm";
import { TemporalDealWorkflowLink } from "../../Links";
import { Badge } from '../../catalyst/badge';
import { ChallengeType } from '../../../utils/challenges';

interface DealListComponentInterface {
  deal: Deal;
}

export default function DealListComponent({
  deal,
}: DealListComponentInterface) {
  const [localDeal, setLocalDeal] = useState<Deal>(deal);
  const [updatedBrandHandle, setUpdatedBrandHandle] = useState(null);
  const [approveReceiptDeal] = useMutation(APPROVE_RECEIPT_DEAL);
  const location = useLocation();
  const { pathname } = location;

  let socialHandle: string = updatedBrandHandle;

  if (localDeal.merchantInstagramHandle) {
    socialHandle = localDeal.merchantInstagramHandle;
  }

  const handleApproveDealClick = async () => {
    try {
      const variables = {
        dealId: deal.id,
      };
      const result = await approveReceiptDeal({ variables });
      if (result.data.approveReceiptDeal) {
        const newDeal: Deal = {
          ...deal,
        };
        newDeal.state = "ACCEPTED";
        setLocalDeal(newDeal);
      }
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const getBadgeColor = (challengeType: ChallengeType) => {
    switch (challengeType) {
      case ChallengeType.Checkin:
        return 'purple';
      case ChallengeType.Photo:
        return 'green';
      default:
        return 'yellow';
    }
  }

  return (
    <>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <TemporalDealWorkflowLink dealId={localDeal.id} />
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div>{localDeal.merchantName ?? localDeal.merchant}</div>
        {socialHandle && (
          <a
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={`https://www.instagram.com/${socialHandle}`}
            target="_blank"
            rel="noreferrer"
          >
            @{socialHandle}
          </a>
        )}
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col ">
          <div>
            <PayDealForm
              deal={localDeal}
              updateDeal={setLocalDeal}
              showRefund={deal.challenge?.type !== ChallengeType.Checkin}
            />
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col items-center space-y-2 ">
          {deal.challenge?.type !== ChallengeType.Checkin && (
            <Link
              className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
              to={`${
                deal.creator
                  ? '/creators/' + deal.creator.id
                  : pathname.split('/').splice(0, 3).join('/')
              }/post?dealId=${deal.id}`}
              rel="noreferrer"
            >
              View media
            </Link>
          )}
          <DealStateComponent state={localDeal.state} />
          <div className="flex">
            {localDeal.state === 'PENDING_RECEIPT' && (
              <div
                className="px-2"
                onClick={handleApproveDealClick}
              >
                <button className="inline-flex items-center rounded-md border border-transparent bg-green-600 px-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:bg-green-700">
                  Approve
                </button>
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        {timeDifferenceForDate(Number(localDeal.createdAt))}
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        {deal.challenge &&
          <Badge color={getBadgeColor(deal.challenge.type as ChallengeType)}>
            {deal.challenge.type}
          </Badge>
        }
      </td>
    </>
  );
}
