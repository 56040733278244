import { useQuery } from '@apollo/client';
import {
  PostRequirementAction,
  RequirementsTableV2,
} from '../components/shared/RequirementsTable';
import { BRAND_CATEGORIES, CHALLENGE_TEMPLATES, POST_REQUIREMENTS } from '../graphql/queries';
import { Spinner } from '@kalecard/common';
import { BrandCategory, ChallengeTemplate, PostRequirement } from '../__generated__/graphql';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/catalyst/dropdown';
import { Field, Fieldset, Label, Legend } from '../components/catalyst/fieldset';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Subheading } from '../components/catalyst/heading';
import { Badge } from '../components/catalyst/badge';
import { Dialog } from '../components/catalyst/dialog';
import { UpdatePostRequirementDefaultsForm } from '../components/forms/UpdatePostRequirementDefaultsForm';
import { ChallengeType } from '../utils/challenges';
import { Checkbox, CheckboxField } from '../components/catalyst/checkbox';

export const addNewItem = (item: any, itemsList: any[], setNewList: React.Dispatch<React.SetStateAction<any[]>>) => {
  const existing = itemsList.find((temp) => temp == item);
  if (!existing) {
    const newItems = [...itemsList, item];
    setNewList(newItems);
  }
}

export const removeItem = (item: any, itemsList: any[], setNewList: React.Dispatch<React.SetStateAction<any[]>>) => {
  const newItems = itemsList.filter((temp) => temp != item);
  setNewList(newItems);
}

export default function ChallengeRequirementsPage() {
  const [selectedBrandCategories, setSelectedBrandCategories] = useState<BrandCategory[]>([]);
  const [selectedChallengeTemplates, setSelectedChallengeTemplates] = useState<ChallengeTemplate[]>([]);
  const [selectedChallengeTypes, setSelectedChallengeTypes] = useState<ChallengeType[]>([]);
  const [isKaleDefault, setIsKaleDefault] = useState(null);
  const [currentRequirement, setCurrentRequirement] = useState(null);
  const [isUpdateDefaultsOpen, setIsUpdateDefaultsOpen] = useState(false);

  const { data, loading } = useQuery(POST_REQUIREMENTS, {
    variables: {
      types: ["CONTENT"],
      brandCategoryIds: selectedBrandCategories?.map((brandCategory) => brandCategory.id),
      challengeTemplateIds: selectedChallengeTemplates?.map((challengeTemplate) => challengeTemplate.id),
      challengeTypes: selectedChallengeTypes,
      isKaleDefault: isKaleDefault
    },
  });

  const { data: brandCategoriesData, loading: brandCategoriesLoading } = useQuery(BRAND_CATEGORIES);
  const { data: challengeTemplatesData, loading: challengeTemplatesLoading } = useQuery(CHALLENGE_TEMPLATES, {
    variables: {
      templateType: "THEME"
    }
  });

  const onKaleDefaultValueClick = (val: boolean) => {
    if (val === isKaleDefault) {
      setIsKaleDefault(null);
    } else {
      setIsKaleDefault(val);
    }
  }

  const challengeTypes = Object.values(ChallengeType);

  if (loading) return <p>Loading...</p>;
  return (
    <div className="mb-4 mt-4 space-y-6 px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="space-y-2">
        <h1 className="text-xl font-semibold text-gray-900">
          Challenge Requirements
        </h1>
        <p className="text-sm text-gray-700">
          A list of all the challenge requirements
        </p>
      </div>

      {/* Filters */}
      <div>
        <Subheading>Filters:</Subheading>
        <div className="flex space-x-6">
          {/* Brand Categories */}
          <Field className="flex items-center space-x-2">
            <Label>Brand Categories</Label>
            {brandCategoriesLoading ? (
              <Spinner size={'h-4 w-4'} />
            ) : (<Dropdown>
              <DropdownButton outline>
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                {brandCategoriesData?.brandCategories.edges.map((brandCategory) => (
                  <DropdownItem
                    key={brandCategory.node.id}
                    onClick={() => addNewItem(brandCategory.node, selectedBrandCategories, setSelectedBrandCategories)}
                  >
                    {brandCategory.node.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>)}
          </Field>

          {/* Challenge Templates */}
          <Field className="flex items-center space-x-2">
            <Label>Challenge Templates</Label>
            {challengeTemplatesLoading ? (
              <Spinner size={'h-4 w-4'} />
            ) : (<Dropdown>
              <DropdownButton outline>
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                {challengeTemplatesData?.challengeTemplates.map((challengeTemplate) => (
                  <DropdownItem
                    key={challengeTemplate.id}
                    onClick={() => addNewItem(challengeTemplate, selectedChallengeTemplates, setSelectedChallengeTemplates)}
                  >
                    {challengeTemplate.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>)}
          </Field>

          {/* Challenge Types */}
          <Field className="flex items-center space-x-2">
            <Label>Challenge Types</Label>
            <Dropdown>
              <DropdownButton outline>
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                {challengeTypes.map((challengeType) => (
                  <DropdownItem
                    key={challengeType}
                    onClick={() => addNewItem(challengeType, selectedChallengeTypes, setSelectedChallengeTypes)}
                  >
                    {challengeType}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Field>

          <Fieldset className="flex items-center space-x-3">
            <Legend>Kale Default</Legend>
            <div>
              <CheckboxField>
                <Checkbox name="yes" value={"yes"} checked={isKaleDefault === true} onClick={() => onKaleDefaultValueClick(true)} />
                <Label>Yes</Label>
              </CheckboxField>
              <CheckboxField>
                <Checkbox name="no" value={"no"} checked={isKaleDefault === false} onClick={() => onKaleDefaultValueClick(false)} />
                <Label>No</Label>
              </CheckboxField>
              </div>
          </Fieldset>
        </div>

        {/* Applied Filtes */}
        <div className="space-x-2 pt-2">
          {selectedBrandCategories.map((brandCategory) => <Badge color="indigo" onClick={() => removeItem(brandCategory, selectedBrandCategories, setSelectedBrandCategories)}><XMarkIcon className="w-4 h-4"/> {brandCategory.name}</Badge>)}
          {selectedChallengeTemplates.map((challengeTemplate) => <Badge color="lime" onClick={() => removeItem(challengeTemplate, selectedChallengeTemplates, setSelectedChallengeTemplates)}><XMarkIcon className="w-4 h-4"/> {challengeTemplate.title}</Badge>)}
          {selectedChallengeTypes.map((challengeType) => <Badge color="cyan" onClick={() => removeItem(challengeType, selectedChallengeTypes, setSelectedChallengeTypes)}><XMarkIcon className="w-4 h-4"/> {challengeType}</Badge>)}
        </div>
      </div>

      {/* Challenge Requirements */}
      {loading ? (
        <Spinner size="h-8 w-8" />
      ) : (
        <RequirementsTableV2
          requirements={data?.postRequirements as PostRequirement[]}
          showBrandDetails={false}
          brand={null}
          actions={[PostRequirementAction.EDIT, PostRequirementAction.UPDATE_DEFAULTS]}
          showMappings={true}
          onUpdateDefaults={(postRequirement) => {
            setCurrentRequirement(postRequirement);
            setIsUpdateDefaultsOpen(true);
          }}
        />
      )}

      <Dialog open={isUpdateDefaultsOpen} onClose={setIsUpdateDefaultsOpen}>
        <UpdatePostRequirementDefaultsForm postRequirement={currentRequirement} onSubmit={() => setIsUpdateDefaultsOpen(false)} />
      </Dialog>
    </div>
  );
}