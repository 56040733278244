import { useMutation, useQuery } from '@apollo/client';
import { Dropdown, Spinner, WhitelistContentData } from '@kalecard/common';
import MediaComponent from '../components/MediaComponent';
import PostStatsComponent from '../components/PostStatsComponent';
import { UPDATE_CONTENT_WHITELIST } from '../graphql/mutations';
import { WHITELIST_CONTENT } from '../graphql/queries';

export default function WhitelistRequestsPage() {
  const visibleStates = [
    'NEW',
    'BRAND_ACCEPTED',
    'CREATOR_ACCEPTED',
    'IN_REVIEW',
    'READY',
    'IN_PROGRESS',
  ];

  const stateMachine = {
    BRAND_ACCEPTED: ['BRAND_ACCEPTED', 'CREATOR_ACCEPTED', 'CREATOR_REJECTED'],
    CREATOR_ACCEPTED: ['CREATOR_ACCEPTED', 'IN_REVIEW'],
    IN_REVIEW: ['IN_REVIEW'],
    READY: ['READY', 'IN_PROGRESS'],
    IN_PROGRESS: ['IN_PROGRESS'],
  };

  const [updateContentWhitelist] = useMutation(UPDATE_CONTENT_WHITELIST, {
    refetchQueries: [WHITELIST_CONTENT],
  });

  const { loading, data, fetchMore } = useQuery<WhitelistContentData>(
    WHITELIST_CONTENT,
    {
      variables: {
        states: visibleStates,
        first: 20,
        after: '0',
      },
    }
  );

  let listFooter = null;
  if (data?.whitelistContent.pageInfo?.hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() =>
          fetchMore({
            variables: {
              visibleStates,
              first: 20,
              after: data?.whitelistContent.edges.length.toString(),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              // Don't do anything if there weren't any new items
              if (
                !fetchMoreResult ||
                fetchMoreResult.whitelistContent?.edges?.length === 0
              ) {
                return previousResult;
              }
              return {
                // Merge the new items with the old ones
                ...previousResult,
                whitelistContent: {
                  ...previousResult?.whitelistContent,
                  edges: [
                    ...(previousResult?.whitelistContent?.edges ?? []),
                    ...(fetchMoreResult?.whitelistContent?.edges ?? []),
                  ],
                  pageInfo: {
                    ...fetchMoreResult?.whitelistContent?.pageInfo,
                  },
                },
              };
            },
          })
        }
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  const handleOptionClicked = async (postWhitelistRequestId, state) => {
    const variables = {
      postWhitelistRequestId,
      state,
    };
    await updateContentWhitelist({ variables });
  };

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Whitelist Requests
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the whitelist requests
            </p>
            <p className="mt-2 text-sm text-gray-700">
              {
                'State transition path: BRAND_ACCEPTED -> CREATOR_ACCEPTED -> IN_REVIEW -> READY -> IN_PROGRESS -> COMPLETED'
              }
            </p>
          </div>
        </div>
        <table className="mt-4 min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                Brand
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                External Username
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                Platform
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                Post
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                Stats
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                Budget
              </th>
              <th
                scope="col"
                className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
              >
                State
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data?.whitelistContent.edges.map((edge) => {
              const { node } = edge;
              const { post } = node;
              const externalUser = post.externalUser;
              const mediaUrl = post?.mediaUrls[0];
              const state =
                node.state === 'NEW' ? 'BRAND_ACCEPTED' : node.state;
              const stateOptions = stateMachine[state];
              console.log('node', node);
              return (
                <tr>
                  <td>{post?.deal?.creatorBrand?.name}</td>
                  <td>
                    <a href={`/creators/${post?.deal?.creator?.id}`}>
                      {externalUser.username}
                    </a>
                  </td>
                  <td>{externalUser.externalPlatform}</td>
                  <td>
                    <a
                      className="flex w-40"
                      href={post.externalPermalink}
                      target="_blank"
                    >
                      <MediaComponent
                        url={mediaUrl?.url || ''}
                        mediaType={mediaUrl?.mediaType || 'VIDEO'}
                      />
                    </a>
                  </td>
                  <td>
                    <PostStatsComponent post={post} />
                  </td>
                  <td>{node.budget}</td>
                  <td>
                    <Dropdown
                      options={stateOptions.map((stateOption) => ({
                        id: stateOption,
                        name: stateOption,
                        imageUrl: null,
                      }))}
                      activeOptionId={state}
                      optionClicked={(e) => handleOptionClicked(node.id, e.id)}
                      label={null}
                    />
                    {state === 'IN_REVIEW' && (
                      <a
                        href={`/creators/${post?.deal?.creator?.id}/actions`}
                        className="text-sm text-blue-500 underline"
                      >
                        Pay Creator
                      </a>
                    )}
                    {state === 'IN_PROGRESS' && (
                      <a
                        href={`/brands/${post?.deal?.creatorBrand?.id}/actions`}
                        className="text-sm text-blue-500 underline"
                      >
                        Charge Brand
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="my-8 flex justify-center">{listFooter}</div>
      </div>
    </>
  );
}
