import { useMutation } from '@apollo/client';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import {
  CreatorProfileData,
  ExternalUser,
  Spinner,
  numberWithCommas,
  prettyDecimal,
} from '@kalecard/common';
import { NavLink } from 'react-router-dom';
import {
  REMOVE_EXTERNAL_USER,
  RESET_CREATOR_BIRTHDATE,
} from '../../graphql/mutations';
import { useState } from 'react';
import { CREATOR_PROFILE_DATA } from '../../graphql/queries';
import CreatorAccountState from './CreatorAccountState';
import {
  getExternalPlatformIcon,
  getExternalPlatformUrl,
} from '../../utils/externalPlatform';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CreatorHeaderInterface {
  creatorProfileData: CreatorProfileData;
}

export default function CreatorHeader({
  creatorProfileData,
}: CreatorHeaderInterface) {
  const [externalUsers, setExternalUsers] = useState<ExternalUser[]>(
    creatorProfileData.creator?.externalUsers
  );
  const [resetCreatorBirthdate] = useMutation(RESET_CREATOR_BIRTHDATE);
  const tabs = [
    { name: 'Deals', href: `deals`, current: false },
    { name: 'Posts', href: `post`, current: false },
    { name: 'Messages', href: `messages`, current: true },
    { name: 'Transactions', href: `transactions`, current: false },
    { name: 'Actions', href: `actions`, current: false },
    { name: 'History', href: `history`, current: false },
  ];

  const handleResetCreatorBirthdate = async () => {
    await resetCreatorBirthdate({
      variables: {
        creatorId: creatorProfileData.creator.id,
      },
      refetchQueries: [CREATOR_PROFILE_DATA],
    });
  };

  let date13YearsAgo = new Date();
  date13YearsAgo.setFullYear(date13YearsAgo.getFullYear() - 13);

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        {creatorProfileData && (
          <div className="flex justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col">
                {externalUsers?.map((externalUser) => {
                  return (
                    <ExternalUserItem
                      key={externalUser.id}
                      externalUser={externalUser}
                      numExternalUsers={externalUsers.length}
                      setExternalUsers={setExternalUsers}
                    />
                  );
                })}
                {externalUsers.length === 0 ? (
                  <>
                    {creatorProfileData.creator.socialHandle ? (
                      <ExternalUserItem
                        key={`instagram_${creatorProfileData.creator.socialHandle}`}
                        externalUser={
                          {
                            username: creatorProfileData.creator.socialHandle,
                            externalPlatform: 'INSTAGRAM',
                            id: `instagram_${creatorProfileData.creator.socialHandle}`,
                          } as ExternalUser
                        }
                        numExternalUsers={2}
                        setExternalUsers={setExternalUsers}
                        canRemove={false}
                      />
                    ) : null}
                    {creatorProfileData.creator.tiktokSocialHandle ? (
                      <ExternalUserItem
                        key={`tiktok_${creatorProfileData.creator.tiktokSocialHandle}`}
                        externalUser={
                          {
                            username:
                              creatorProfileData.creator.tiktokSocialHandle,
                            externalPlatform: 'TIKTOK',
                            id: `instagram_${creatorProfileData.creator.tiktokSocialHandle}`,
                          } as ExternalUser
                        }
                        numExternalUsers={2}
                        setExternalUsers={setExternalUsers}
                        canRemove={false}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="flex flex-col text-left text-base">
                <div>{creatorProfileData.creator.phoneNumber}</div>
                <div>{creatorProfileData.creator.name}</div>
                <div>{creatorProfileData.creator.email}</div>

                <div className="flex">
                  Plaid{' '}
                  {creatorProfileData.creator.creatorSettings?.isPlaidActive ? (
                    <CheckCircleIcon
                      className="-mr-0.5 ml-2 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <MinusCircleIcon
                      className="-mr-0.5 ml-2 h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col text-left text-base">
                <div>
                  Referral Code:{' '}
                  <span className="font-bold">
                    {creatorProfileData.creator.referralCode}
                  </span>
                </div>
                <div>
                  Completed Challenges and Drops:{' '}
                  <span className="font-bold">
                    {numberWithCommas(creatorProfileData.dealsCompleted)}
                  </span>
                </div>
                <div>
                  Total Earnings:{' '}
                  <span className="font-bold">
                    ${prettyDecimal(creatorProfileData.totalEarnings)}
                  </span>
                </div>
                <div>
                  Current Balance:{' '}
                  <span className="font-bold">
                    ${prettyDecimal(creatorProfileData?.creator?.balance)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <CreatorAccountState
                state={creatorProfileData.creator.accountState}
              />
            </div>
            {creatorProfileData.creator.accountState ===
              'SCHEDULED_FOR_BLOCKED_UNDERAGE' && (
              <div className="flex items-center space-x-3">
                <button
                  onClick={handleResetCreatorBirthdate}
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Reset Birthdate
                </button>
              </div>
            )}
          </div>
        )}
      </h3>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label
            htmlFor="current-tab"
            className="sr-only"
          >
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                activeClassName={
                  'border-indigo-500 text-indigo-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                }
                className={
                  'whitespace-nowrap border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-indigo-300 hover:text-indigo-700'
                }
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

function ExternalUserItem({
  externalUser,
  numExternalUsers,
  setExternalUsers,
  canRemove = true,
}: {
  externalUser: ExternalUser;
  numExternalUsers: number;
  setExternalUsers: React.Dispatch<React.SetStateAction<ExternalUser[]>>;
  canRemove?: boolean;
}) {
  const [removeExternalUserMutation, { loading, error }] =
    useMutation(REMOVE_EXTERNAL_USER);

  const removeExternalUser = async () => {
    await removeExternalUserMutation({
      variables: {
        externalUserId: externalUser.id,
      },
    });
    setExternalUsers((externalUsers) =>
      externalUsers.filter((user) => user.id !== externalUser.id)
    );
  };

  return (
    <div className="flex flex-col">
      <div
        key={externalUser.id}
        className="flex items-center"
      >
        <a
          href={getExternalPlatformUrl(
            externalUser.username,
            externalUser.externalPlatform
          )}
          target="_blank"
          className="block cursor-pointer rounded p-1 hover:bg-gray-100 hover:text-green-800"
          rel="noreferrer"
        >
          <div className="flex items-center space-x-1">
            <img
              className={`${
                numExternalUsers > 1 ? 'h-5 w-5' : 'h-12 w-12'
              } rounded-full`}
              src={externalUser.pictureUrl}
              alt=""
            />

            <div className="flex items-center text-base text-green-600">
              {externalUser.username}
            </div>
          </div>
        </a>
        <div className="ml-1 flex items-center text-base ">
          | {numberWithCommas(externalUser.followers)} followers |{' '}
          <span className="ml-1 flex items-center justify-center">
            <FontAwesomeIcon
              className={'h-5 w-5'}
              icon={
                getExternalPlatformIcon(
                  externalUser.externalPlatform
                ) as IconProp
              }
            />
          </span>
          <span className="ml-1 font-bold">
            {externalUser.isPrivate && '| Private'}
          </span>
        </div>
        {externalUser.status === 'CONNECTED' ? (
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <MinusCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        )}
        {canRemove ? (
          <div className="flex space-x-2">
            <button
              disabled={loading}
              className="disabled:opacity-60"
              onClick={() => {
                removeExternalUser();
              }}
            >
              <XCircleIcon
                className="ml-2 h-5 w-5 rounded-lg shadow-lg"
                aria-hidden="true"
              />
            </button>
            {loading && <Spinner size="h-4 w-4" />}
          </div>
        ) : null}
      </div>
      {error && <div className="text-xs text-red-500">{error.message}</div>}
    </div>
  );
}
