import { useQuery } from '@apollo/client';
import { CHALLENGE_TEMPLATES } from '../../graphql/queries';
import { Button } from '../catalyst/button';
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Dialog } from '../catalyst/dialog';
import NewChallengeTemplateForm from '../forms/NewChallengeTemplateForm';
import ChallengeTemplateTable from './ChallengeTemplateTable';
import { Spinner } from '@kalecard/common';

export default function ChallengeTrends() {
  const [showNewTrendForm, setShowNewTrendForm] = useState(false);
  const { data, loading, refetch } = useQuery(CHALLENGE_TEMPLATES, {
    variables: {
      templateType: 'TREND',
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="space-y-4">
      {/* Buttons */}
      <div className="flex justify-end space-x-2">
        <Button
          outline
          onClick={() => refetch()}
          className="hover:cursor-pointer"
        >
          <ArrowPathIcon />
        </Button>
        <Button
          outline
          className="hover:cursor-pointer"
          onClick={() => setShowNewTrendForm(true)}
        >
          <PlusIcon />
        </Button>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <Spinner size={'h-6 w-6'} />
        </div>
      ) : (
        <ChallengeTemplateTable
          templateType={'TREND'}
          challengeTemplates={data.challengeTemplates}
        />
      )}

      <Dialog
        open={showNewTrendForm}
        onClose={setShowNewTrendForm}
      >
        <NewChallengeTemplateForm
          templateType="TREND"
          onSubmit={() => setShowNewTrendForm(false)}
        />
      </Dialog>
    </div>
  );
}
