import {
  faInstagram,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export function getExternalPlatformUrl(username: string, platform: string) {
  switch (platform) {
    case 'TIKTOK':
      return `https://www.tiktok.com/@${username}`;
    case 'INSTAGRAM':
      return `https://www.instagram.com/${username}`;
    case 'YOUTUBE':
      return `https://www.youtube.com/c/${username}`;
    default:
      return null;
  }
}

export function getExternalPlatformName(platform: string) {
  switch (platform) {
    case 'TIKTOK':
      return 'TikTok';
    case 'INSTAGRAM':
      return 'Instagram';
    case 'YOUTUBE':
      return 'YouTube';
    default:
      return null;
  }
}
export function getExternalPlatformIcon(platform: string) {
  switch (platform) {
    case 'TIKTOK':
      return faTiktok;
    case 'INSTAGRAM':
      return faInstagram;
    case 'YOUTUBE':
      return faYoutube;
    default:
      return null;
  }
}
