import { useMutation, useQuery } from '@apollo/client';
import { Brand, Challenge } from '../../__generated__/graphql';
import { PHOTO_INSPIRATION } from '../../graphql/queries';
import { Dialog } from '../catalyst/dialog';
import { useState } from 'react';
import { Button } from '../catalyst/button';
import UploadMedia from '../forms/UploadMedia';
import { UPDATE_CHALLENGE_PHOTO_INSPIRATION_MEMBERSHIP } from '../../graphql/mutations';
import { ChallengeType } from '../../utils/challenges';

export default function ChallengeInspiration({
  challenge,
}: {
  challenge: Challenge;
}) {
  const { data, loading } = useQuery(PHOTO_INSPIRATION, {
    variables: { challengeId: challenge.id },
  });

  const [showBrandLibraryDialog, setShowBrandLibraryDialog] = useState(false);

  const [updatePhotoInspirationMembership] = useMutation(
    UPDATE_CHALLENGE_PHOTO_INSPIRATION_MEMBERSHIP,
    {
      refetchQueries: [PHOTO_INSPIRATION],
    }
  );
  return !challenge || !challenge.type || challenge.type !== ChallengeType.Photo ? (
    <div className="flex flex-col items-center p-16">
      <p className="text-gray-500">
        Inspiration currently only supported for photo challenges
      </p>
    </div>
  ) : (
    <div className="mt-4 flex flex-col items-center">
      <div className="flex flex-wrap justify-center p-4">
        {loading && <p>Loading...</p>}
        {data?.photoInspiration?.edges.map((edge) => (
          <div
            key={edge.node.id}
            className="relative w-1/4 p-2"
          >
            <button
              type="button"
              className="absolute left-0 top-0 m-2 rounded-full bg-red-500 px-3 py-1 text-white"
              onClick={() => {
                updatePhotoInspirationMembership({
                  variables: {
                    challengeId: challenge.id,
                    photosToAdd: [],
                    photosToRemove: [edge.node.id],
                  },
                });
              }}
            >
              X
            </button>
            <img
              src={edge.node.mediaUrl.url}
              alt={edge.node.id}
              className="max-h-1/2 object-cover"
            />
          </div>
        ))}
      </div>
      <Button
        type="button"
        color="blue"
        onClick={() => {
          setShowBrandLibraryDialog(true);
        }}
      >
        See Brand Library
      </Button>

      <Dialog
        size="4xl"
        onClose={() => setShowBrandLibraryDialog(false)}
        open={showBrandLibraryDialog}
      >
        <BrandPhotoInspiration
          brand={challenge?.brand}
          addPhotoIds={(photoIds) => {
            updatePhotoInspirationMembership({
              variables: {
                challengeId: challenge.id,
                photosToAdd: photoIds,
                photosToRemove: [],
              },
            });
          }}
          closeModal={() => setShowBrandLibraryDialog(false)}
        />
      </Dialog>
    </div>
  );
}

function BrandPhotoInspiration({
  brand,
  addPhotoIds,
  closeModal,
}: {
  brand: Brand;
  addPhotoIds: (photoIds: string[]) => void;
  closeModal: () => void;
}) {
  const { data, loading, refetch } = useQuery(PHOTO_INSPIRATION, {
    variables: { brandId: brand.id },
  });
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  return (
    <div className="flex flex-col items-center p-16">
      <div className="mt-4 flex flex-col items-center">
        <div className="flex flex-wrap justify-center p-4">
          {loading && <p>Loading...</p>}
          {data?.photoInspiration?.edges.map((edge) => (
            <div
              key={edge.node.id}
              className="p-2"
              onClick={() => {
                if (selectedPhotos.includes(edge.node.id)) {
                  setSelectedPhotos(
                    selectedPhotos.filter((id) => id !== edge.node.id)
                  );
                } else {
                  setSelectedPhotos([...selectedPhotos, edge.node.id]);
                }
              }}
            >
              <img
                src={edge.node.mediaUrl.url}
                alt={edge.node.id}
                className={`max-h-36 object-cover ${
                  selectedPhotos.includes(edge.node.id)
                    ? 'border-4 border-blue-500'
                    : ''
                }`}
              />
            </div>
          ))}
        </div>
        <UploadMedia
          uploadType="ADMIN_PHOTO_UPLOAD"
          metadata={{
            data: [{ key: 'brandId', value: brand.id }],
          }}
          callback={() => {
            refetch();
          }}
          small
        />
        <Button
          type="button"
          color="green"
          onClick={() => {
            addPhotoIds(selectedPhotos);
            closeModal();
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
