import { useMutation, useQuery } from '@apollo/client';
import BrandTransactionForm from '../components/forms/BrandTransactionForm';
import { Spinner } from '@kalecard/common';
import {
  SET_BRAND_PAUSE_CHALLENGES,
  TRIGGER_BRAND_CONTENT_BUDGET_INVOICE,
} from '../graphql/mutations';
import React, { useState } from 'react';
import {
  Brand,
  BrandsQuery,
  GetBrandBillingQuery,
  GetBrandBillingQueryVariables,
} from '../__generated__/graphql';
import { BRAND_BILLING } from '../graphql/queries';
import { Button } from '../components/catalyst/button';
import { formatCurrency } from '../utils/currency';
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from '../components/catalyst/dialog';

export default function BrandActionsPage({ brand }: { brand: Brand }) {
  return (
    <div className="flex flex-col space-y-2">
      <div>
        <TriggerBrandContentBudgetBillingButton brand={brand} />
      </div>
      <PauseBrandButton brand={brand} />
      <BrandTransactionForm brand={brand} />
    </div>
  );
}

function TriggerBrandContentBudgetBillingButton({ brand }: { brand: Brand }) {
  let [isOpen, setIsOpen] = useState(false);

  const { data, loading, error } = useQuery<
    GetBrandBillingQuery,
    GetBrandBillingQueryVariables
  >(BRAND_BILLING, {
    variables: {
      id: brand?.id,
    },
  });

  const [triggerBrandContentBudgetInvoice] = useMutation(
    TRIGGER_BRAND_CONTENT_BUDGET_INVOICE
  );

  const handleTriggerInvoice = async () => {
    await triggerBrandContentBudgetInvoice({
      variables: {
        brandId: brand.id,
      },
    });
    setIsOpen(false);
  };

  if (error) {
    return <div>Error loading content budget information</div>;
  }

  return (
    <div className="space-y-2 rounded-md border-2 p-2">
      <h3 className="text-base">Content budget actions</h3>
      <Button
        disabled={loading || !data?.brand.remainingBudgetUntilCharged}
        onClick={() => setIsOpen(true)}
      >
        Trigger Invoice
      </Button>
      <div className="text-sm font-medium text-slate-700">
        {formatCurrency(data?.brand?.remainingBudgetUntilCharged)} content
        budget remaining until invoice is sent automatically.
      </div>
      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        size="sm"
      >
        <DialogTitle>Confirm triggering brand content budget</DialogTitle>
        <DialogDescription>
          Note: sending the invoice may be delayed for up to 6 hours.
        </DialogDescription>
        <DialogActions>
          <Button
            plain
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleTriggerInvoice}>Trigger Invoice</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function PauseBrandButton({ brand }: { brand: Brand }) {
  const [isLoading, setIsLoading] = useState(false);
  const [setBrandPauseChallenges] = useMutation(SET_BRAND_PAUSE_CHALLENGES);
  const [isChallengesPaused, setIsChallengesPaused] = useState(
    brand.challengesPaused
  );

  return (
    <div className="space-y-2 rounded-md border-2 p-2">
      <h3 className="text-base">Pause/Resume brand challenges</h3>
      <div className="flex space-x-2">
        <button
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const result = await setBrandPauseChallenges({
              variables: {
                brandId: brand.id,
                shouldPause: !isChallengesPaused,
              },
            });
            if (result.data?.setBrandPauseChallenges) {
              setIsChallengesPaused(!isChallengesPaused);
            }
            setIsLoading(false);
          }}
          className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 ${
            isChallengesPaused
              ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-2 disabled:bg-green-300'
              : 'bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-300'
          }`}
        >
          {isChallengesPaused
            ? 'Resume brand challenges'
            : 'Pause brand challenges'}
        </button>
        {isLoading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}
