import { BrandCategory, Connection, Edge } from '@kalecard/common';
import { DocumentNode } from 'graphql';
import apolloClient from '../config/apolloClient';
import { BRAND_CATEGORIES, BRANDS, KALE_BRANDS } from '../graphql/queries';
import {
  Brand,
  BrandEdge,
  BrandsQuery,
  BrandsQueryVariables,
} from '../__generated__/graphql';

interface BrandsData {
  brands: Connection<Brand>;
}

interface BrandCategoriesData {
  brandCategories: Connection<BrandCategory>;
}

function getBrands(
  query: DocumentNode,
  first: number,
  after?: string,
  onlyRelevantBrands?: boolean,
  isKaleBrand?: boolean,
  sortingType?: string
) {
  return apolloClient.query<BrandsQuery, BrandsQueryVariables>({
    query: query,
    variables: {
      first: first,
      after: after,
      onlyRelevantBrands: onlyRelevantBrands,
      isKaleBrand: isKaleBrand,
      sortingType: sortingType,
    },
  });
}

export function getKaleBrands(
  first: number,
  after?: string,
  autoRechargeEnabled?: boolean,
  challengesPaused?: boolean,
  sortingType?: string,
  pacingWithSage?: boolean
) {
  return apolloClient.query<BrandsData>({
    query: KALE_BRANDS,
    variables: {
      first: first,
      after: after,
      autoRechargeEnabled: autoRechargeEnabled,
      challengesPaused: challengesPaused,
      sortingType: sortingType,
      isKaleBrand: true,
      pacingWithSage: pacingWithSage,
    },
  });
}

function getBrandCategories(first: number, after?: string) {
  return apolloClient.query<BrandCategoriesData>({
    query: BRAND_CATEGORIES,
    variables: {
      first: first,
      after: after,
    },
  });
}

export async function getAllBrands(
  query: DocumentNode,
  onlyRelevantBrands?: boolean,
  isKaleBrand?: boolean,
  sortingType?: string
): Promise<Brand[]> {
  const first = 100;
  let after = 0;
  let hasNextPage = true;
  let brands: Brand[] = [];

  while (hasNextPage) {
    const result = await getBrands(
      query,
      first,
      String(after),
      onlyRelevantBrands,
      isKaleBrand,
      sortingType
    );
    after = after + result.data.brands.edges.length;
    hasNextPage = result.data.brands.pageInfo.hasNextPage;

    brands = [
      ...brands,
      ...result.data.brands.edges.map((edge) => edge.node as Brand),
    ];
  }

  return brands;
}

export async function getAllBrandCategories(): Promise<BrandCategory[]> {
  const first = 100;
  let after = 0;
  let hasNextPage = true;
  let brands: Edge<BrandCategory>[] = [];

  while (hasNextPage) {
    const result = await getBrandCategories(first, String(after));
    after = after + result.data.brandCategories.edges.length;
    hasNextPage = result.data.brandCategories.pageInfo.hasNextPage;

    const newBrands = [...brands, ...result.data.brandCategories.edges];
    brands = newBrands;
  }

  return brands.map((edge) => edge.node);
}
