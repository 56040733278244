import { useMutation, useQuery } from '@apollo/client';
import { OptionInterface, Spinner } from '@kalecard/common';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CREATE_CHALLENGE } from '../../graphql/mutations';
import { BRANDS, CHALLENGE_DEFAULT_REQUIREMENTS } from '../../graphql/queries';
import { getAllBrands } from '../../utils/brands';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../catalyst/button';
import {
  ChallengeFormDataComponent,
  getRoundedDate,
} from '../challenges/SharedChallengeComponents';
import {
  Brand,
  BrandProduct,
  Challenge,
  PostRequirement,
} from '../../__generated__/graphql';
import { ChallengeType } from '../../utils/challenges';

type NewChallengeFormInputs = {
  description: string;
  audienceType: string;
  newTag: string;
  newCreatorId: string;
  exampleUrl: string;
  title: string;
  type: string;
};

interface NewChallengeFormInterface {
  setNewChallenge?: (newChallenge: Challenge) => void;
  onSubmit?: (newChallenge: Challenge) => void;
  currentChallenge?: Challenge;
}

export default function NewChallengeForm({
  setNewChallenge,
  onSubmit,
  currentChallenge,
}: NewChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { reset, register, handleSubmit, watch, getValues, setValue } =
    useForm<NewChallengeFormInputs>();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brandOptions, setBrandOptions] = useState<OptionInterface[]>();
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [brandAudience, setBrandAudience] = useState<OptionInterface[]>([]);
  const [difficulty, setDifficulty] = useState(1);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [createChallenge] = useMutation(CREATE_CHALLENGE);
  const [selectedBrandProduct, setSelectedBrandProduct] = useState(null);
  const [isChallengeReviewRequired, setIsChallengeReviewRequired] =
    useState(false);
  const [postRequirements, setPostRequirements] = useState([]);
  const [challengePostRequirements, setChallengePostRequirements] = useState(
    []
  );
  const [type, setType] = useState(ChallengeType.Post);
  const [challengeTemplate, setChallengeTemplate] = useState(null);

  const { data: defaultPostRequirementsData } = useQuery(CHALLENGE_DEFAULT_REQUIREMENTS, {
    variables: {
      brandId: selectedBrandId,
      challengeType: type,
      challengeTemplateId: challengeTemplate?.id
    }
  });

  const getChallenge = (
    description: string,
    audienceType: string,
    brandId: string,
    difficulty: number,
    requiredBrandProducts: BrandProduct[],
    postRequirements: PostRequirement[],
    challengePostRequirements: PostRequirement[],
    newTags?: Set<string>,
    exampleUrl?: string,
    title?: string
  ): Challenge => {
    const brand = brands.find((brand) => brand.id === brandId);
    var mentionRequirements = null;
    if (brand) {
      mentionRequirements = 'Tag ' + brand.name;
      if (brand.socialHandle === brand.tiktokSocialHandle) {
        mentionRequirements = 'Tag ' + brand.socialHandle;
      }
    }

    return {
      id: '-1',
      description: description,
      brand: brand as Brand,
      audienceType,
      mentionRequirements,
      state: 'INACTIVE',
      exampleUrl: exampleUrl,
      difficulty: difficulty,
      requiredBrandProducts: requiredBrandProducts as BrandProduct[],
      postRequirements: [...challengePostRequirements, ...postRequirements],
      title: title,
    } as Challenge;
  };

  const updateChallenge = (
    brandId: string,
    difficulty: number,
    requiredBrandProducts: BrandProduct[],
    postRequirements: PostRequirement[],
    challengePostRequirements: PostRequirement[],
    newTags?: Set<string>
  ) => {
    setNewChallenge(
      getChallenge(
        getValues('description'),
        getValues('audienceType'),
        brandId,
        difficulty,
        requiredBrandProducts,
        postRequirements,
        challengePostRequirements,
        newTags,
        getValues('exampleUrl'),
        getValues('title')
      )
    );
  };

  const brandClicked = (brandOption: OptionInterface) => {
    setSelectedBrandId(brandOption.id);

    const newTags = new Set<string>();
    if (brandOption.hashtagRequirement) {
      // Break down by spaces
      brandOption.hashtagRequirement.split(' ').forEach((word) => {
        newTags.add(word);
      });
    }
    if (brandOption.kaleHashtags) {
      // Break down by spaces
      brandOption.kaleHashtags.split(' ').forEach((word) => {
        newTags.add(word);
      });
    }

    setBrandAudience((prevData) => {
      return [brandOption];
    });
  };

  useEffect(() => {
    getAllBrands(BRANDS, true).then((brands) => {
      setBrands(brands as Brand[]);
      setBrandOptions(
        brands.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            imageUrl: brand.logoUrl,
            hashtagRequirement: brand.hashtagRequirement,
            kaleHashtags: brand.kaleHashtagRequirements?.join(' '),
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    let postRequirements = [];
    if (selectedBrandId) {
      setError(null);
      const selectedBrand = brands.find(
        (brand) => brand.id === selectedBrandId
      );
      setIsChallengeReviewRequired(
        selectedBrand?.isChallengeReviewRequired === true
      );
    }

    const requiredBrandProducts = selectedBrandProduct
      ? [selectedBrandProduct]
      : [];
    updateChallenge(
      selectedBrandId,
      difficulty,
      requiredBrandProducts,
      postRequirements,
      challengePostRequirements
    );
  }, [selectedBrandId]);

  useEffect(() => {
    if (defaultPostRequirementsData) {
      setChallengePostRequirements(defaultPostRequirementsData.challengeDefaultRequirements);
    }

    const requiredBrandProducts = selectedBrandProduct
      ? [selectedBrandProduct]
      : [];
    updateChallenge(
      selectedBrandId,
      difficulty,
      requiredBrandProducts,
      postRequirements,
      challengePostRequirements
    );
  }, [defaultPostRequirementsData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'exampleUrl' || name === 'description' || name === 'title') {
        const requiredBrandProducts = selectedBrandProduct
          ? [selectedBrandProduct]
          : [];
        updateChallenge(
          selectedBrandId,
          difficulty,
          requiredBrandProducts,
          postRequirements,
          challengePostRequirements
        );
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    selectedBrandId,
    difficulty,
    selectedBrandProduct,
    postRequirements,
    challengePostRequirements,
  ]);

  useEffect(() => {
    const requiredBrandProducts = selectedBrandProduct
      ? [selectedBrandProduct]
      : [];
    updateChallenge(
      selectedBrandId,
      difficulty,
      requiredBrandProducts,
      postRequirements,
      challengePostRequirements
    );
  }, [
    selectedBrandId,
    difficulty,
    selectedBrandProduct,
    postRequirements,
    challengePostRequirements,
  ]);

  const submit: SubmitHandler<NewChallengeFormInputs> = async (
    data: NewChallengeFormInputs
  ) => {
    setIsLoading(true);
    try {
      const exampleUrl = getValues('exampleUrl');
      const result = await createChallenge({
        variables: {
          brandId: selectedBrandId,
          description: data.description,
          audienceType: 'ALL',
          brandIds: brandAudience.map((option) => option.id),
          exampleUrl: exampleUrl,
          difficulty: difficulty,
          startDate: getRoundedDate(startDate)?.toString(),
          endDate: getRoundedDate(endDate)?.toString(),
          brandProductId: selectedBrandProduct?.id,
          challengePostRequirements: challengePostRequirements?.map(
            (postRequirement) => postRequirement.id
          ),
          title: data.title,
          type: type,
          templateId: challengeTemplate?.id
        },
      });
      console.log(result);
      onSubmit(result.data.createChallenge as Challenge);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form
      className="w-full"
      onKeyDown={(e) => {
        // Prevent this form from submitting when the user presses enter inside
        // the form on a field; specifically annoying in hashtag selector
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <ChallengeFormDataComponent
        brandOptions={brandOptions}
        brands={brands as Brand[]}
        selectedBrandId={selectedBrandId}
        brandClicked={brandClicked}
        isChallengeReviewRequired={isChallengeReviewRequired}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBrandProduct={selectedBrandProduct}
        setSelectedBrandProduct={setSelectedBrandProduct}
        challengePostRequirements={challengePostRequirements}
        setChallengePostRequirements={setChallengePostRequirements}
        setError={setError}
        isEditForm={false}
        currentChallenge={currentChallenge}
        type={type}
        setType={setType}
        challengeTemplate={challengeTemplate}
        setChallengeTemplate={setChallengeTemplate}
      />
      <div className="mt-4">
        <div className="flex justify-end space-x-2">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="button"
              onClick={handleSubmit(submit)}
              disabled={isLoading}
              color="indigo"
              className="hover:cursor-pointer"
            >
              Create Challenge
            </Button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}
