import { useLazyQuery } from '@apollo/client';
import { NOTIFICATION_CAMPAIGNS } from '../graphql/queries';
import { Button } from '../components/catalyst/button';
import { useEffect, useState } from 'react';
import { Modal } from '@kalecard/common';
import CreateForm from '../components/notifications/CreateForm';
import { Heading } from '../components/catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/catalyst/table';
import { MessageCampaign } from '../__generated__/graphql';
import { Badge } from '../components/catalyst/badge';

export default function () {
  const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] =
    useState(false);
  const [getNotificationCampaigns] = useLazyQuery(NOTIFICATION_CAMPAIGNS);
  const [campaigns, setCampaigns] = useState<MessageCampaign[]>([]);

  async function getCampaigns() {
    const result = await getNotificationCampaigns({
      fetchPolicy: 'no-cache',
    });

    console.log(result);

    setCampaigns(
      result.data.notificationCampaigns.edges.map(
        (edge) => edge.node
      ) as MessageCampaign[]
    );
  }

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleNewCampaignCreated = () => {
    setIsCreateNotificationModalOpen(false);
    getCampaigns();
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <Heading>Notification Campaigns</Heading>
        <Button
          type="submit"
          color="indigo"
          onClick={() => {
            setIsCreateNotificationModalOpen(true);
          }}
        >
          New Notification Campaign
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Text Message</TableHeader>
            <TableHeader>Created By</TableHeader>
            <TableHeader>Created At</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign.id}>
              <TableCell>{campaign.name}</TableCell>
              <TableCell>{campaign.textMessage}</TableCell>
              <TableCell>
                <Badge color="zinc">
                  {' '}
                  {campaign.createdBy
                    ? campaign.createdBy.name
                    : 'Unknown'}{' '}
                </Badge>
              </TableCell>
              <TableCell>
                <Badge color="emerald">
                  {' '}
                  {campaign.createdAt
                    ? new Date(Number(campaign.createdAt)).toLocaleDateString(
                        'en-us',
                        { hourCycle: 'h12', hour: '2-digit', minute: '2-digit' }
                      )
                    : 'Unknown'}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal
        isModalOpen={isCreateNotificationModalOpen}
        onClose={() => setIsCreateNotificationModalOpen(false)}
      >
        <CreateForm onSubmitModal={handleNewCampaignCreated} />
      </Modal>
    </div>
  );
}
