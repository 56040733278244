import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { OptionInterface, Spinner } from '@kalecard/common';
import { useContext, useEffect, useState } from 'react';
import { ADMIN_CHALLENGES, CHALLENGE_TEMPLATES } from '../../graphql/queries';
import BrandsDropdown from '../brands/BrandsDropdown';
import ChallengeRow from './ChallengeRow';
import { ChallengesContext } from '../../providers/ChallengesProvider';
import {
  ChallengesActionType,
  ChallengeSortingType,
  challengesSortingTypes,
  SortableChallengeColumnType,
} from '../../reducers/ChallengesReducer';
import { classNames } from '../../utils/style';
import { Field, Label } from '../catalyst/fieldset';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '../catalyst/dropdown';
import { Challenge } from '../../__generated__/graphql';

export default function ChallengesTable({
  newChallengesOnly,
}: {
  newChallengesOnly: boolean;
}) {
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [challengeId, setChallengeId] = useState<string>(null);
  const {
    state: {
      challenges,
      hasNextPage,
      after,
      challengeState,
      newChallenges,
      sortingType,
      keywords,
      fetchPolicy,
      brands,
      challengeIds,
      shouldUpdateChallenges,
      sortableColumns,
      additionalFilters,
      source,
      challengeTemplateId,
    },
    dispatch: challengesDispatch,
  } = useContext(ChallengesContext);

  const [getAdminChallenges] = useLazyQuery(ADMIN_CHALLENGES);

  const { data: challengeTemplatesData } = useQuery(CHALLENGE_TEMPLATES, {
    variables: {
      templateType: 'TREND',
    },
  });

  const getChallenges = async () => {
    setIsLoading(true);
    console.log(additionalFilters);
    const brandIds = brands?.map((brand) => brand.id);
    const result = await getAdminChallenges({
      variables: {
        after: after,
        state: challengeState?.id,
        sortingType: sortingType?.id,
        keywords: keywords,
        brandIds: brandIds,
        challengeIds: challengeIds,
        commercialSoundsOnly: additionalFilters?.commercialSoundsOnly,
        containingFeedback: additionalFilters?.containingFeedback,
        source: source,
        challengeTemplateId: challengeTemplateId,
      },
      fetchPolicy: fetchPolicy,
    });
    setIsLoading(false);
    return result.data;
  };

  const states = [
    { id: 'ACTIVE', name: 'Active', imageUrl: null },
    { id: 'INACTIVE', name: 'Inactive', imageUrl: null },
    {
      id: 'PENDING_BRAND_REVIEW',
      name: 'Pending Brand Approval',
      imageUrl: null,
    },
  ];

  const getMoreChallenges = async () => {
    setLoadingMore(true);
    await getChallenges().then((data) => {
      challengesDispatch({
        type: ChallengesActionType.UPDATE_CHALLENGES,
        payload: {
          challenges: data.adminChallenges.edges.map((edge) => edge.node),
          hasNextPage: data.adminChallenges.pageInfo.hasNextPage,
        },
      });
    });
    setLoadingMore(false);
  };

  const updateChallenges = () => {
    getChallenges().then((data) => {
      challengesDispatch({
        type: ChallengesActionType.SET_CHALLENGES,
        payload: {
          challenges: data.adminChallenges.edges.map((edge) => edge.node),
          hasNextPage: data.adminChallenges.pageInfo.hasNextPage,
        },
      });
    });
  };

  const setNewBrand = (newBrand: OptionInterface) => {
    challengesDispatch({
      type: ChallengesActionType.ADD_BRAND_FILTER,
      payload: {
        id: newBrand.id,
        name: newBrand.name,
      },
    });
  };

  const setNewState = (newState: OptionInterface) => {
    if (newState.id !== challengeState?.id) {
      challengesDispatch({
        type: ChallengesActionType.ADD_STATE_FILTER,
        payload: {
          challengeState: {
            id: newState.id,
            name: newState.name,
          },
        },
      });
    }
  };

  const resetFilters = () => {
    challengesDispatch({
      type: ChallengesActionType.RESET_FILTERS,
    });
  };

  const removeBrandFilter = (brand: OptionInterface) => {
    challengesDispatch({
      type: ChallengesActionType.REMOVE_BRAND_FILTER,
      payload: {
        id: brand.id,
        name: brand.name,
      },
    });
  };

  const removeStateFilter = () => {
    challengesDispatch({
      type: ChallengesActionType.REMOVE_STATE_FILTER,
    });
  };

  const setSortingType = (newSortingType: ChallengeSortingType) => {
    if (newSortingType.id !== sortingType?.id) {
      challengesDispatch({
        type: ChallengesActionType.SET_SORTING_TYPE,
        payload: {
          sortingType: newSortingType,
        },
      });
    }
  };

  const addKeyword = (newKeyword: string) => {
    if (newKeyword !== '') {
      const updatedKeywords = [...keywords, newKeyword];
      updateKeywords(updatedKeywords);
    }
    setKeyword(null);
  };

  const removeKeyword = (keyword: string) => {
    if (keywords.find((item) => item === keyword)) {
      const updatedKeywords = keywords.filter((item) => item !== keyword);
      updateKeywords(updatedKeywords);
    }
  };

  const updateKeywords = (updatedKeywords: string[]) => {
    challengesDispatch({
      type: ChallengesActionType.UPDATE_KEYWORDS,
      payload: {
        keywords: updatedKeywords,
      },
    });
  };

  const addChallengeIdFilter = (challengeId: string) => {
    console.log(challengeId);
    if (challengeId !== '') {
      challengesDispatch({
        type: ChallengesActionType.ADD_CHALLENGE_ID_FILTER,
        payload: challengeId,
      });
    }
  };

  const removeChallengeIdFilter = (challengeId: string) => {
    challengesDispatch({
      type: ChallengesActionType.REMOVE_CHALLENGE_ID_FILTER,
      payload: challengeId,
    });
  };

  const removeFeedbackFilter = () => {
    challengesDispatch({
      type: ChallengesActionType.REMOVE_FEEDBACK_FILTER,
    });
  };

  const removeCommercialSoundsOnlyFilter = () => {
    challengesDispatch({
      type: ChallengesActionType.TOGGLE_COMMERCIAL_SOUNDS_ONLY_FILTER,
      payload: false,
    });
  };

  const removeChallengeTemplateFilter = () => {
    challengesDispatch({
      type: ChallengesActionType.REMOVE_TEMPLATE_FILTER,
    });
  };

  const addChallengeTemplateFilter = (challengeTemplateId: string) => {
    challengesDispatch({
      type: ChallengesActionType.ADD_TEMPLATE_FILTER,
      payload: {
        challengeTemplateId: challengeTemplateId,
      },
    });
  };

  const displayFilter = (
    id: string,
    name: string,
    onClick: (param?: any) => void,
    prefix?: string,
    param?: any
  ) => {
    return (
      <div
        key={id}
        className="mb-2 mr-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-4 text-sm font-medium text-kale-green-500"
      >
        <div className="flex items-center">
          <p>
            {prefix && <b>{prefix}:</b>} {name}
          </p>
          <div
            className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:cursor-pointer hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
            onClick={() => onClick(param)}
          >
            <XMarkIcon className="p-1" />
          </div>
        </div>
      </div>
    );
  };

  const handleSortableColumnClick = (
    sortableColumnType: SortableChallengeColumnType
  ) => {
    challengesDispatch({
      type: ChallengesActionType.SELECT_SORTABLE_COLUMN,
      payload: sortableColumnType,
    });
  };

  useEffect(() => {
    if (shouldUpdateChallenges === true) {
      updateChallenges();
    }
  }, [shouldUpdateChallenges]);

  const areNoFiltersApplied =
    (!brands || brands.length === 0) &&
    !challengeState &&
    (!keywords || keywords.length === 0) &&
    (!challengeIds || challengeIds.length === 0) &&
    !additionalFilters.commercialSoundsOnly &&
    !additionalFilters.containingFeedback &&
    !challengeTemplateId;

  const claimsColumn = sortableColumns.find(
    (col) => col.key === SortableChallengeColumnType.CLAIMS
  );
  const postsColumn = sortableColumns.find(
    (col) => col.key === SortableChallengeColumnType.POSTS
  );
  const postRateColumn = sortableColumns.find(
    (col) => col.key === SortableChallengeColumnType.POST_RATE
  );

  return (
    <>
      {(!newChallengesOnly || newChallenges.length > 0) && (
        <div className="mt-1 flex flex-col">
          <div>
            {!newChallengesOnly && (
              <div className="my-4 justify-between">
                <h1 className="text-base font-semibold text-gray-900">
                  Filters
                </h1>
                {/* Filter inputs and sort by dropdown */}
                <div className="flex justify-between">
                  <div className="flex items-end space-x-2">
                    <BrandsDropdown
                      brandId={brands[0]?.id || '-1'}
                      setBrandId={(brandId) => null}
                      setBrand={setNewBrand}
                      includeDefaultOption={true}
                    />
                    <div>
                      <p className="block pb-1 text-sm font-medium">Keyword:</p>
                      <input
                        type="text"
                        className="border border-gray-300 text-sm"
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && keyword) {
                            addKeyword(keyword);
                            e.currentTarget.value = '';
                          }
                        }}
                      ></input>
                    </div>
                    <div>
                      <p className="block pb-1 text-sm font-medium">
                        Challenge ID:
                      </p>
                      <input
                        type="text"
                        className="border border-gray-300 text-sm"
                        onChange={(e) => setChallengeId(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && challengeId) {
                            addChallengeIdFilter(challengeId);
                            e.currentTarget.value = '';
                          }
                        }}
                      ></input>
                    </div>
                    <button
                      type="button"
                      onClick={resetFilters}
                      disabled={areNoFiltersApplied}
                      className={classNames(
                        areNoFiltersApplied
                          ? `bg-gray-400`
                          : `bg-indigo-600 hover:bg-indigo-700`,
                        `items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto`
                      )}
                    >
                      Reset
                    </button>
                  </div>
                  <Field className="space-x-2">
                    <Label>Sort By:</Label>
                    <Dropdown>
                      <DropdownButton outline>
                        {sortingType?.name}
                        <ChevronDownIcon />
                      </DropdownButton>
                      <DropdownMenu>
                        {challengesSortingTypes.map((sortingType) => (
                          <DropdownItem
                            key={sortingType.id}
                            onClick={() => setSortingType(sortingType)}
                          >
                            {sortingType.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Field>
                </div>
                <div className="mt-2 flex space-x-4">
                  <Field className="flex items-center space-x-2">
                    <Label>State:</Label>
                    <Dropdown>
                      <DropdownButton outline>
                        <ChevronDownIcon />
                      </DropdownButton>
                      <DropdownMenu>
                        {states.map((state) => (
                          <DropdownItem
                            key={state.id}
                            onClick={() => setNewState(state)}
                          >
                            {state.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Field>

                  <Field className="flex items-center space-x-2">
                    <Label>Trend:</Label>
                    <Dropdown>
                      <DropdownButton outline>
                        <ChevronDownIcon />
                      </DropdownButton>
                      <DropdownMenu>
                        {challengeTemplatesData?.challengeTemplates?.map(
                          (challengeTemplate) => (
                            <DropdownItem
                              key={challengeTemplate.id}
                              onClick={() =>
                                addChallengeTemplateFilter(challengeTemplate.id)
                              }
                            >
                              {challengeTemplate.title}
                            </DropdownItem>
                          )
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Field>
                </div>
                {/* Applied Filters */}
                <div className="mt-4 flex flex-wrap">
                  {additionalFilters.containingFeedback &&
                    displayFilter(
                      'FEEDBACK',
                      'Containing Feedback',
                      removeFeedbackFilter
                    )}
                  {additionalFilters.commercialSoundsOnly &&
                    displayFilter(
                      'COMMERCIAL_SOUNDS_ONLY',
                      'Commercial Sounds Only',
                      removeCommercialSoundsOnlyFilter
                    )}
                  {brands?.length > 0 &&
                    brands.map((brand) => {
                      return displayFilter(
                        brand.id,
                        brand.name,
                        removeBrandFilter,
                        'Brand',
                        brand
                      );
                    })}
                  {challengeState &&
                    displayFilter(
                      challengeState.id,
                      challengeState.name,
                      removeStateFilter,
                      'State'
                    )}
                  {keywords?.length > 0 &&
                    keywords.map((keyword) => {
                      return displayFilter(
                        keyword,
                        keyword,
                        removeKeyword,
                        'Keyword',
                        keyword
                      );
                    })}
                  {challengeIds?.length > 0 &&
                    challengeIds.map((id) => {
                      return displayFilter(
                        id,
                        id,
                        removeChallengeIdFilter,
                        'Challenge ID',
                        id
                      );
                    })}
                  {challengeTemplateId &&
                    (() => {
                      const template =
                        challengeTemplatesData?.challengeTemplates?.find(
                          (template) => template.id === challengeTemplateId
                        );
                      return displayFilter(
                        challengeTemplateId,
                        template?.title,
                        removeChallengeTemplateFilter,
                        'Trend'
                      );
                    })()}
                </div>
              </div>
            )}
            {newChallengesOnly && (
              <div className="mb-4">
                <h1 className="mt-4 text-base font-semibold text-gray-900">
                  New Challenges
                </h1>
              </div>
            )}
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {sortingType?.id === 'RANK_ASC' && (
                        <th
                          scope="col"
                          className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Below Challenge ID
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Brand
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-4 text-left text-sm font-semibold text-gray-900"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className={classNames(
                          claimsColumn.value.selected === true
                            ? `font-bold text-indigo-600`
                            : `font-semibold text-gray-900`,
                          `px-3 py-4 text-left text-sm hover:cursor-pointer hover:font-bold`
                        )}
                      >
                        <div
                          className="flex items-center"
                          onClick={() =>
                            handleSortableColumnClick(claimsColumn.key)
                          }
                        >
                          {claimsColumn.value.name}
                          {claimsColumn.value.selected === true ? (
                            <ChevronDownIcon className="ml-1 h-4 w-4 text-indigo-600" />
                          ) : (
                            <ChevronUpDownIcon className="ml-1 h-4 w-4" />
                          )}
                        </div>
                      </th>
                      <th
                        scope="col"
                        className={classNames(
                          postsColumn.value.selected === true
                            ? `font-bold text-indigo-600`
                            : `font-semibold text-gray-900`,
                          `px-3 py-4 text-left text-sm hover:cursor-pointer hover:font-bold`
                        )}
                      >
                        <div
                          className="flex items-center"
                          onClick={() =>
                            handleSortableColumnClick(postsColumn.key)
                          }
                        >
                          {postsColumn.value.name}
                          {postsColumn.value.selected === true ? (
                            <ChevronDownIcon className="ml-1 h-4 w-4 text-indigo-600" />
                          ) : (
                            <ChevronUpDownIcon className="ml-1 h-4 w-4" />
                          )}
                        </div>
                      </th>
                      <th
                        scope="col"
                        className={classNames(
                          postRateColumn.value.selected === true
                            ? `font-bold text-indigo-600`
                            : `font-semibold text-gray-900`,
                          `whitespace-nowrap px-3 py-4 text-left text-sm hover:cursor-pointer hover:font-bold`
                        )}
                      >
                        <div
                          className="flex items-center"
                          onClick={() =>
                            handleSortableColumnClick(postRateColumn.key)
                          }
                        >
                          {postRateColumn.value.name}
                          {postRateColumn.value.selected === true ? (
                            <ChevronDownIcon className="ml-1 h-4 w-4 text-indigo-600" />
                          ) : (
                            <ChevronUpDownIcon className="ml-1 h-4 w-4" />
                          )}
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {(!isLoading || loadingMore) && (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {(newChallengesOnly ? newChallenges : challenges)?.map(
                        (challenge) => {
                          return (
                            <ChallengeRow
                              key={challenge.id}
                              challenge={challenge as Challenge}
                            />
                          );
                        }
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              {(isLoading || loadingMore) && (
                <div className="mt-2 flex w-full justify-center">
                  <Spinner size="h-5 w-5" />
                </div>
              )}
              {hasNextPage && !newChallengesOnly && !isLoading && (
                <div className="mt-2 flex w-full justify-center">
                  <button
                    type="button"
                    onClick={getMoreChallenges}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Load more
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
