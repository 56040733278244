import { useMutation } from '@apollo/client';
import { Deal, Spinner } from '@kalecard/common';
import { useState } from 'react';
import { UNDO_DEAL } from '../graphql/mutations';

export default function RefundDealComponent({
  deal,
  updateDeal,
  setDealState,
}: {
  deal: Deal;
  updateDeal?: Function;
  setDealState?: (dealState: string) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [undoDeal] = useMutation(UNDO_DEAL);

  return (
    <div>
      <div className="flex space-x-2">
        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-700"
          onClick={async () => {
            setIsLoading(true);
            try {
              const result = await undoDeal({
                variables: {
                  dealId: deal.id,
                },
              });
              if (result.data.undoDeal) {
                const newDeal: Deal = {
                  ...deal,
                };
                newDeal.state = 'UNDONE';
                newDeal.rewardAmount = deal.rewardAmount * -1;
                updateDeal && updateDeal(newDeal);
                setDealState && setDealState(newDeal.state);
              }
              console.log(result);
            } catch (err) {
              console.error(err);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Refund
        </button>
        {isLoading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}
