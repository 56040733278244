import { useMutation, useQuery } from "@apollo/client";
import { BrandCategory, ChallengeTemplate, PostRequirement } from "../../__generated__/graphql";
import { Subheading } from "../catalyst/heading";
import { Strong, Text } from "../catalyst/text";
import { requirementForMention, TypeBadge } from "../shared/RequirementsTable";
import { BRAND_CATEGORIES, CHALLENGE_TEMPLATES, POST_REQUIREMENTS } from "../../graphql/queries";
import { ChallengeType } from "../../utils/challenges";
import { useState } from "react";
import { Field, Label } from "../catalyst/fieldset";
import { Spinner } from "@kalecard/common";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../catalyst/dropdown";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { addNewItem, removeItem } from "../../pages/ChallengeRequirementsPage";
import { Badge } from "../catalyst/badge";
import { Button } from "../catalyst/button";
import { UPDATE_POST_REQUIREMENT_MAPPINGS } from "../../graphql/mutations";

export function UpdatePostRequirementDefaultsForm({ postRequirement, onSubmit }: { postRequirement: PostRequirement, onSubmit: () => void }) {
    const [selectedBrandCategories, setSelectedBrandCategories] = useState<BrandCategory[]>(postRequirement.mappings?.brandCategories ?? []);
    const [selectedChallengeTemplates, setSelectedChallengeTemplates] = useState<ChallengeTemplate[]>(postRequirement.mappings?.challengeTemplates ?? []);
    const [selectedChallengeTypes, setSelectedChallengeTypes] = useState<ChallengeType[]>(postRequirement.mappings?.challengeTypes as ChallengeType[] ?? []);
    const [loading, setLoading] = useState(false);

    const { data: brandCategoriesData, loading: brandCategoriesLoading } = useQuery(BRAND_CATEGORIES);
    const { data: challengeTemplatesData, loading: challengeTemplatesLoading } = useQuery(CHALLENGE_TEMPLATES, {
        variables: {
            templateType: "THEME"
        }
    });
    const challengeTypes = Object.values(ChallengeType);
    const [updatePostRequirementMappings] = useMutation(UPDATE_POST_REQUIREMENT_MAPPINGS);

    const submit = async () => {
        setLoading(true);
        try {
          const result = await updatePostRequirementMappings({
            variables: {
              postRequirementId: postRequirement.id,
              brandCategoryIds: selectedBrandCategories.map((brandCategory) =>  brandCategory.id),
              challengeTemplateIds: selectedChallengeTemplates.map((challengeTemplate) => challengeTemplate.id),
              challengeTypes: selectedChallengeTypes
            },
            refetchQueries: [POST_REQUIREMENTS],
          });
          console.log(result);
          onSubmit();
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      };

    return (
        <form className="space-y-4">
            <div className="space-y-1">
                <Text><Strong>Requirement:</Strong></Text>
                <div className="space-y-1 rounded-md border bg-gray-50 p-4">
                    <Text>
                        {postRequirement.type == 'MENTION'
                            ? requirementForMention(
                                postRequirement.referenceBrand,
                                postRequirement.externalPlatform
                            )
                            : postRequirement.requirement}
                    </Text>
                    <div className="w-fit">
                        <TypeBadge type={postRequirement.type} />
                    </div>
                </div>
            </div>
            <Subheading>Adjust the default mappings for this post requirement below.</Subheading>

            {/* Brand Categories */}
            <Field className="flex flex-col space-y-2">
                <Label>Brand Categories</Label>
                {brandCategoriesLoading ? (
                    <Spinner size={'h-4 w-4'} />
                ) : (
                    <Dropdown>
                        <DropdownButton outline className="w-fit">
                            Select a brand category <ChevronDownIcon />
                        </DropdownButton>
                        <DropdownMenu>
                            {brandCategoriesData?.brandCategories.edges.map((brandCategory) => (
                                <DropdownItem
                                    key={brandCategory.node.id}
                                    onClick={() => addNewItem(brandCategory.node, selectedBrandCategories, setSelectedBrandCategories)}
                                >
                                    {brandCategory.node.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>)
                }
                <div className="flex flex-wrap gap-2">
                    {selectedBrandCategories.map((brandCategory) => <Badge color="indigo" onClick={() => removeItem(brandCategory, selectedBrandCategories, setSelectedBrandCategories)}><XMarkIcon className="h-4 w-4" />{brandCategory.name}</Badge>)}
                </div>
            </Field>

            {/* Challenge Templates */}
            <Field className="flex flex-col space-y-2">
                <Label>Challenge Templates</Label>
                {challengeTemplatesLoading ? (
                    <Spinner size={'h-4 w-4'} />
                ) : (
                    <Dropdown>
                        <DropdownButton outline className="w-fit">
                            Select a challenge template <ChevronDownIcon />
                        </DropdownButton>
                        <DropdownMenu>
                            {challengeTemplatesData?.challengeTemplates.map((challengeTemplate) => (
                                <DropdownItem
                                    key={challengeTemplate.id}
                                    onClick={() => addNewItem(challengeTemplate, selectedChallengeTemplates, setSelectedChallengeTemplates)}
                                >
                                    {challengeTemplate.title}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>)
                }
                <div className="flex flex-wrap gap-2">
                    {selectedChallengeTemplates.map((challengeTemplate) => <Badge color="lime" onClick={() => removeItem(challengeTemplate, selectedChallengeTemplates, setSelectedChallengeTemplates)}><XMarkIcon className="h-4 w-4" />{challengeTemplate.title}</Badge>)}
                </div>
            </Field>

            {/* Challenge Types */}
            <Field className="flex flex-col space-y-2">
                <Label>Challenge Types</Label>
                <Dropdown>
                    <DropdownButton outline className="w-fit">
                        Select a challenge type <ChevronDownIcon />
                    </DropdownButton>
                    <DropdownMenu>
                        {challengeTypes.map((challengeType) => (
                            <DropdownItem
                                key={challengeType}
                                onClick={() => addNewItem(challengeType, selectedChallengeTypes, setSelectedChallengeTypes)}
                            >
                                {challengeType}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <div className="flex flex-wrap gap-2">
                    {selectedChallengeTypes.map((challengeType) => <Badge color="cyan" onClick={() => removeItem(challengeType, selectedChallengeTypes, setSelectedChallengeTypes)}><XMarkIcon className="h-4 w-4" />{challengeType}</Badge>)}
                </div>
            </Field>

            <div className="flex space-x-2 justify-end items-center">
                {loading && <Spinner size={'h-5 w-5'} /> }
                <Button color="indigo" onClick={submit} >Save changes</Button>
            </div>
        </form>
    );
}