import { Spinner } from '@kalecard/common';
import { useEffect, useState } from 'react';
import BrandsTable from '../components/brands/BrandsTable';
import { BRANDS } from '../graphql/queries';
import { getAllBrands } from '../utils/brands';
import { Brand } from '../__generated__/graphql';

export default function NonKaleBrandsPage() {
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    getAllBrands(BRANDS, null, false, 'CREATED_AT_DESC').then((brandData) =>
      setBrands(brandData)
    );
  }, []);

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Brands</h1>
          </div>
        </div>
        <div className="mt-4">
          <h1 className="text-base font-semibold text-gray-900">
            Non-Kale Brands
          </h1>
          {brands.length === 0 ? (
            <div className="flex justify-center pt-2">
              <Spinner size={'h-8 w-8'} />
            </div>
          ) : (
            <BrandsTable
              isKaleBrand={false}
              brands={brands as Brand[]}
            />
          )}
        </div>
      </div>
    </>
  );
}
