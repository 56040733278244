import { ImageWithTracking, VideoWithTracking } from '@kalecard/common';

interface MediaComponentProps {
  url: string;
  mediaType: string;
  autoPlay?: boolean;
}

export default function MediaComponent({
  url,
  mediaType,
  autoPlay = false,
}: MediaComponentProps) {
  if (mediaType === 'IMAGE') {
    return (
      <ImageWithTracking
        component="MediaComponent"
        key={url}
        src={url}
        onError={(e) => ((e.target as HTMLImageElement).style.display = 'none')}
        className="flex:1 max-w-15 px-5 pt-5"
      ></ImageWithTracking>
    );
  } else if (mediaType === 'VIDEO') {
    return (
      <VideoWithTracking
        component="MediaComponent"
        controls={true}
        controlsList="nofullscreen noremoteplayback noplaybackrate"
        disablePictureInPicture={true}
        key={url}
        src={url}
        autoPlay={autoPlay}
        loop={true}
        muted={true}
        playsInline={true}
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        onError={(e) => ((e.target as HTMLVideoElement).style.display = 'none')}
        className="flex:1 h-full w-full rounded-2xl object-cover p-2"
      ></VideoWithTracking>
    );
  } else {
    return null;
  }
}
