import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from '../../catalyst/fieldset';
import { Spinner } from '@kalecard/common';
import { Button } from '../../catalyst/button';
import { useMutation } from '@apollo/client';
import { UPDATE_BRAND_SETTINGS } from '../../../graphql/mutations';
import { BrandSettingsField } from './BrandSettingsField';
import { Brand } from '../../../__generated__/graphql';
import { Input } from '../../catalyst/input';

interface BrandSettingsInput {
  requiresHighQualityScore: boolean;
  isOffersActive: boolean;
  isChallengeReviewRequired: boolean;
  isSelfService: boolean;
  minCreatorAgeYears: number;
  maxCreatorAgeYears: number;
}

export default function BrandSettings({ brand }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const [updateBrandSettings] = useMutation(UPDATE_BRAND_SETTINGS);

  const { handleSubmit, getValues, setValue, register } =
    useForm<BrandSettingsInput>({
      defaultValues: {
        ...brand,
      },
    });

  const onSubmit = async (data: BrandSettingsInput) => {
    if (
      data.requiresHighQualityScore == null ||
      data.isChallengeReviewRequired == null ||
      data.isSelfService == null
    ) {
      setErrorMessage('Please select an option for every setting.');
      clearErrorMessage();
      return;
    }

    setLoading(true);
    console.log(data);
    try {
      const variables = {
        brandId: brand.id,
        requiresHighQualityScore: data.requiresHighQualityScore,
        isOffersActive: false,
        isChallengeReviewRequired: data.isChallengeReviewRequired,
        isSelfService: data.isSelfService,
        maxCreatorAgeYears: data.maxCreatorAgeYears,
        minCreatorAgeYears: data.minCreatorAgeYears,
      };

      await updateBrandSettings({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage('Congrats! Your changes have been applied.');
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to apply your changes, try again later.');
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form
      className="space-y-4"
      key="settings"
    >
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Brand Settings</Legend>
        </div>
        <FieldGroup>
          <div className="grid grid-cols-2 gap-16">
            <div className="col-span-1 space-y-4">
              <BrandSettingsField
                key={
                  'requiresHighQualityScore' +
                  getValues('requiresHighQualityScore')
                }
                label="High quality creators only"
                description="Whether we enable this brand's challenges only for content creators with high quality scores."
                name="requiresHighQualityScore"
                value={getValues('requiresHighQualityScore')}
                setValue={setValue}
              />
              <BrandSettingsField
                key={
                  'isChallengeReviewRequired' +
                  getValues('isChallengeReviewRequired')
                }
                label="Challenge review requirement"
                description="Whether the brand needs to review all created challenges before they go live."
                name="isChallengeReviewRequired"
                value={getValues('isChallengeReviewRequired')}
                setValue={setValue}
              />
            </div>
            <div className="col-span-1 space-y-4">
              <BrandSettingsField
                key={'isSelfService' + getValues('isSelfService')}
                label="Self service"
                description="Whether the brand is a self-service brand or 'off' for a full-service brand."
                name="isSelfService"
                value={getValues('isSelfService')}
                setValue={setValue}
              />
              <div className="flex flex-row gap-4">
                <Field>
                  <div>
                    <Label>Creator Min Age</Label>
                  </div>
                  <Input
                    {...register('minCreatorAgeYears', { valueAsNumber: true })}
                    step={1}
                    name="minCreatorAgeYears"
                    type="number"
                  ></Input>
                </Field>
                <Field>
                  <div>
                    <Label>Creator Max Age</Label>
                  </div>
                  <Input
                    {...register('maxCreatorAgeYears', { valueAsNumber: true })}
                    step={1}
                    name="maxCreatorAgeYears"
                    type="number"
                  ></Input>
                </Field>
              </div>
            </div>
          </div>
        </FieldGroup>
      </Fieldset>

      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button
          color="indigo"
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandSettingsStepComplete(brand: Brand) {
  return (
    brand.autoRechargeEnabled != null &&
    brand.recurringChargesEnabled != null &&
    brand.autoChargeDefaultPaymentMethod != null &&
    brand.enableWireTransferInvoicePayment != null &&
    brand.isKaleOffsetEnabled != null &&
    brand.requiresHighQualityScore != null &&
    brand.isOffersActive != null &&
    brand.isChallengeReviewRequired != null &&
    brand.minCreatorAgeYears != null &&
    brand.maxCreatorAgeYears != null
  );
}
